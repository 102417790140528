@import '~bootstrap/scss/bootstrap.scss';

$primary: #8098AA;
$danger: #DE7163;

//General

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  /* background-color: #8097aa; */
}

.loadingOverlay {
  margin-left: 10px;
  margin-top: 10px;
  position: absolute;
}

// Search

.metamodelSearchDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap:10px;
  width: 100%;
}

// Elements

.metaDataAndConstantsMainDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}

.metaDataAndConstantsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.metamodelElements {
  max-height: 900px;
  width: 90%;
  min-width: 500px;
  overflow-y: auto;
}

.metamodelElementWithObjects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metamodelElement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.objectsMainDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  gap: 2px;
  margin-top: 20px;
}

.objectDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: solid 1px #8098AA;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: smaller;
  border-radius: 5px;
}

//Buttons

.primaryButton {
  background-color: $primary;
  border: none;
  &:hover {
    background-color: lighten($primary, 10%);
  }
  &:active {
    background-color: lighten($primary, 10%);
    transform: translateY(4px);
  }
  &:active:hover  {
    background-color: lighten($primary, 10%);
  }
  &:disabled  {
    background-color: lighten($primary, 10%);
  }
}

// links

a:hover {
  pointer: cursor;
  transform: scale(1.1);
}

// Accordion

.accordion-button-danger > h2 > button {
  background-color: $danger;
  border: none;
  &:hover {
    background-color: lighten($danger, 10%);
  }
  &:active {
    background-color: lighten($danger, 10%);
    transform: translateY(4px);
  }
  &:active:hover  {
    background-color: lighten($danger, 10%);
  }
  &:disabled  {
    background-color: lighten($danger, 10%); 
  }
}

.accordion-button-danger .accordion-button:not(.collapsed) {
  background-color: lighten($danger, 10%);
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}
